import { graphql, Link } from 'gatsby';
import { Seo } from '../components/Seo';
//styles
import { colors, device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';
import camera from '../images/technology/intraoral-camera.png';
import xray from '../images/technology/digital-xray.png';
import screen from '../images/technology/screen.png';
import laser from '../images/technology/diagnodent-laser.png';
import { Technology } from '../components/Technology';
import { DoctorsSlide } from '../components/DoctorsSlide';

const BreadCrumbs = styled.div`
    ul {
        display: none;
    }

    @media ${device.tablet} {
        ul {
            cursor: pointer;
            display: flex;
            z-index: 200;
            justify-content: space-around;
            margin: 10em auto -5em;
            font-size: 13px;
            width: 220px;
            li {
                color: grey;
                &:hover {
                    color: black;
                }
            }
        }
    }
`;
const Card = styled.div`
    padding: 0em 1em;
    background: ${colors.white};
    padding-top: 3em;
    h2 {
        font-size: 32px;
        line-height: 58px;
        margin: 0.5em 0em;
    }
    p {
        padding-bottom: 2em;
    }
    @media ${device.tablet} {
        h2 {
            margin-bottom: 1em;
            font-size: 48px;
        }
        p {
            font-size: 21px;
        }
        padding-top: 8em;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-direction: ${({ imageSide }) => (imageSide === 'right' ? 'row' : 'row-reverse')};
        align-items: center;
        .text {
            padding: 0em 8em;
        }
        div {
            width: 50vw;
        }
        li {
            font-size: 21px;
            margin: 0.5em 0em;
            line-height: 28px;
        }
    }
    @media ${device.laptopL} {
        width: 1400px;
    }
`;

const Review = styled.section`
    margin: 3em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        color: ${colors.green};
        padding-bottom: 2em;
    }
    h3 {
        text-align: center;
        font-size: 34px;
        letter-spacing: -1px;
        line-height: 46px;
        font-weight: 700;
        padding: 0 1em;
        margin-bottom: 1em;
    }
    h5 {
        margin: 0.5em 0em;
        font-weight: 700;
        font-size: 64px;
    }
    p {
        text-align: center;
        width: 140px;
        margin-bottom: 2em;
    }
    @media ${device.tablet} {
        h3 {
            font-size: 45px;
        }
        .awards {
            p {
                width: 180px;
            }
            display: flex;
            div {
                text-align: center;
                margin: 0em 3em;
            }
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DentalTechnology: Page<any> = ({ data }) => {
    return (
        <main style={{ paddingTop: '2em', background: 'white' }}>
            <Seo
                title="Prosthodontics & Implant Therapy Technology in Tampa Florida – SmileTampa"
                description="Learn about available technologies for various treatments and procedures. From x-rays to cameras and CT scans."
            />
            <BreadCrumbs>
                <ul>
                    <Link to="/">
                        <li style={{ listStyle: 'none' }}>HOME</li>
                    </Link>
                    <li style={{ color: 'black' }}>TECHNOLOGY</li>
                </ul>
            </BreadCrumbs>
            <Card imageSide="right">
                <div className="x text">
                    <h2>Single Visit Dentistry</h2>
                    <ul>
                        <li>
                            Restorations can be created in a single visit – a more efficient
                            workflow with minimized patient risk
                        </li>
                        <li>
                            Appointment scheduling is optimized, reducing waiting time and social
                            interactions
                        </li>
                        <li>
                            Every step of the workflow is being done in the practice reducing
                            cross-contamination risk
                        </li>
                        <li>
                            With longer individual sittings, cleaning and disinfection protocols
                            must be performed fewer times a day
                        </li>
                    </ul>
                </div>
                <div>
                    <img alt="dental device with screen" src={screen} />
                </div>
            </Card>
            <Technology techPage />
            <Card imageSide="right">
                <div>
                    <img src={camera} alt="probe" />
                </div>
                <div className="text">
                    <h2>INTRAORAL CAMERA</h2>
                    <p>
                        An intraoral camera is a handheld appliance that is shaped like a wand. The
                        tiny camera at the tip of the appliance takes hundreds of pictures per
                        second to capture an in-depth and accurate image of your mouth. This image
                        is sometimes used to create a digital impression of your teeth and gums. A
                        digital impression is particularly useful for same-day dental crowns.
                    </p>
                </div>
            </Card>

            <Card>
                <div>
                    <img src={xray} alt="x-ray for dental purposes" />
                </div>
                <div className="text">
                    <h2>DIGITAL X-RAY</h2>
                    <p>
                        In the past, most dental professionals had to use the traditional x-ray
                        systems, which require film development. These days, we use digital x-rays
                        due to their many benefits. A digital x-ray is safer and much quicker than
                        its film predecessor. If the image comes out blurry, we can instantly retake
                        a new one. Digital x-rays are a crucial diagnostic tool that helps us gain a
                        better understanding of the inner workings of your mouth, more specifically
                        the teeth, roots, and alveolar bone.
                    </p>
                </div>
            </Card>
            <Card imageSide="right">
                <div>
                    <img src={laser} alt="laser box machine" />
                </div>
                <div className="text">
                    <h2>DIAGNODENT LASER</h2>
                    <p>
                        The diagnodent laser allows us to more effectively identify the presence of
                        decay in the occlusal (bite) surface of a tooth. The diagnodent laser
                        measures laser fluorescence within the mineral structure of a tooth. This
                        allows for the detection of healthy tooth structure, as well as smooth
                        surface dental caries (tooth decay) at their earliest stage. Early detection
                        of tooth decay helps us effectively treat the problem before it requires a
                        costly and invasive restoration.
                    </p>
                </div>
            </Card>
            <Review>
                <span>WHAT WE DID</span>
                <h3>Fast, accurate and 3D scans, all in Realtime.</h3>
                <div className="awards">
                    <div>
                        <h5>50K</h5>
                        <p>images per second</p>
                    </div>
                    <div>
                        <h5>3D</h5>
                        <p>realtime scan as soon as you start</p>
                    </div>
                    <div>
                        <h5>35+</h5>
                        <p>over 35 years of Cerec</p>
                    </div>
                </div>
            </Review>
            <DoctorsSlide images={data.doctors.edges} />
        </main>
    );
};
export default DentalTechnology;

export const query = graphql`
    query {
        doctors: allFile(filter: { relativeDirectory: { eq: "doctorCards" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
    }
`;
DentalTechnology.Layout = DefaultLayout;
